// Base desktop button styling
.button {
    font-family: 'IQOS', 'Helvetica Neue', sans-serif;
    appearance: none;
    color: #34303d;
    border-radius: 36px;
    cursor: pointer;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    outline: none;
    padding: 12px 25px;
    box-shadow: inset 0 0 0 2px #34303d;
    text-align: center;
    text-decoration: none;
    transition: background 0.2s, box-shadow 0.3s ease-out, color 0.2s;
    user-select: none;
    vertical-align: middle;
    white-space: nowrap;
    letter-spacing: 0.5px;
    overflow: hidden;
    position: relative;
    z-index: 1;
    background: transparent;
    border: none;
    will-change: background, box-shadow, color;
    width: min-content;

    @media (hover: hover) {
        &:hover {
            text-decoration: none;
            transition: background 0.15s 0.2s, box-shadow 0.15s 0.25s ease-out, color 0.2s;
            outline-width: 0;
            color: #1c1a20;
            box-shadow: inset 0 0 0 2px transparent;

            &:after {
                transform: translate(-10%, -50%) skew(-30deg);
                opacity: 1;
            }
        }
    }

    &:active,
    &:focus {
        outline: none;
    }

    &:disabled {
        cursor: default;
        pointer-events: none;
        box-shadow: none;
        background: #7b7881;
        color: #e1e0e2;
        &:after {
            background: #7b7881;
            color: #e1e0e2;
        }
    }

    &:after {
        content: ' ';
        position: absolute;
        top: 50%;
        left: 0;
        background: #00d1d2;
        width: 120%;
        height: 120%;
        z-index: -1;
        transition: transform 0.25s ease-out;
        transform: translate(-101%, -50%) skew(0deg);
        opacity: 1;
    }
}

// Button types
.light {
    background: #fffdfb;
    box-shadow: inset 0 0 0 2px #fffdfb;
}

.lightBordered {
    background: #fffdfb;
    box-shadow: inset 0 0 0 2px #34303d;

    &:hover{

    }
}

.darkWithBlue {
    background: #34303d;
    color: #fffdfb;
}

.transparent {
    background: transparent;
    box-shadow: inset 0 0 0 2px #34303d;
}

.transparentWithWhite {
    background: transparent;
    box-shadow: inset 0 0 0 2px #34303d;
    &:after {
        background: #fffdfb;
    }
}

.borderWhite {
    box-shadow: inset 0 0 0 2px #fffdfb;
    color: #fffdfb;
}

.transparent {
    background: transparent;
    box-shadow: inset 0 0 0 2px #34303d;
}

.whiteTransparent {
    background: transparent;
    box-shadow: inset 0 0 0 2px #fffdfb;
    color: #fffdfb;
    &:after {
        background: #fffdfb;
    }
}

.white {
    &:after {
        background: #fffdfb;
    }
}

.dark {
    color: #34303d;
    box-shadow: inset 0 0 0 2px #34303d;

    &:after {
        box-shadow: inset 0 0 0 2px #34303d;
        background: #fffdfb;
    }
}
