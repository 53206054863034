.formContainer {
    display: flex;
    flex-flow: row;
    width: 90%;
    max-width: 1024px;
    margin: 0 auto;
    align-items: center;

    .popupContent{
        display: flex;
        flex-flow: column;
        margin: 0 auto;
        padding: 0 !important;
        justify-content: center;
        align-items: center;
        &::-webkit-scrollbar {
            width: 0;
            background: transparent; /* make scrollbar transparent */
        }

        @media only screen and (max-width: 768px) {
            width: 100%;
        }

        .popupMessage {
            display: flex;
            flex-flow: column;
            width: 70%;
            img.closeImg {
                width: 25px;
                height: 25px;
                cursor: pointer;
                position: absolute;
                top: 15px;
                right: 25px;
                background: transparent;
                padding: 0;
                z-index: 4;
            }

            @media only screen and (max-width: 768px) {
                width: 100%;
            }

            .formResponse {
                display: flex;
                flex-flow: column;

                h2 {
                    font-size: 24px;
                    font-weight: bold;
                    text-align: left;
                    padding: 0;

                    @media only screen and (max-width: 768px) {
                        padding: 0;
                    }
                }
                p {
                    font-weight: 500;
                    text-align: left;
                    color: #34303d;
                    
                    b {
                        color: #34303d;
                    }

                    @media only screen and (max-width: 768px) {
                        padding: 0;
                    }
                }

                img {
                    position: static;
                    float: left;
                    margin: 0;
                    width: 100%;
                    max-width: 50px;
                }
            }
        }
        .popupIcon {
            width: 50%;
            align-items: center;
            justify-content: center;
            display: flex;

            @media only screen and (max-width: 768px) {
                width: 100%;
            }

            img {
                position: static;
                float: right;
                background: none;
                height: auto;
                border-radius: 0;

                @media only screen and (max-width: 768px) {
                    padding: 0;
                }
            }
        }
    }

    @media only screen and (max-width: 768px) {
        flex-direction: column-reverse;
    }


    .formInputs {
        width: 100%;
        max-width: 712px;
        margin-top: 20px;
        .split {
            display: flex;
            flex-flow: row;
            label {
                margin: 10px auto 50px 15px;
                position: relative;
                background: #FFFDFB;

                @media only screen and (max-width: 768px) {
                    margin: 10px auto 50px auto;
                }

                input {
                    background-color: #FFFDFB !important;

                }
                p {
                    position: absolute;
                    top: -13px;
                    left: 20px;
                    background: #FFFDFB;
                    width: auto;
                    border-right: 4px solid #FFFDFB;
                    border-left: 4px solid #FFFDFB;
                    font-weight: 400;
                }
                &:nth-child(2) {
                    margin-right: 0;
                }
            }
            @media only screen and (max-width: 768px) {
                flex-flow: column;
            }
        }
        label {
            margin: 0 10px 30px;
            width: 100%;
            position: relative;
            text-align: left;
        }

        p {
            color: #000;
            width: 100%;
            font-weight: 500;
            float: left;
            font-size: 16px;
            text-align: left;
        }
        span {
            padding-left: 0;

            line-height: 18px;
            text-align: left;

            font-size: 14px;
            color: #ff144b;
            display: block;
            text-transform: none;
            margin-top: 30px;

            position: absolute;
            bottom: -40px;
            width: 100%;
            min-width: 165px;
        }
        .error {
            padding-left: 0;
            line-height: 17px;
            text-align: left;
            font-size: 14px;
            color: #ff144b;
            display: block;
            text-transform: none;
            position: absolute;
            bottom: -17px;
            width: 100%;
            min-width: 161px;
        }
        input,
        select,
        textarea {
            background-color: #ffffff !important;
            padding: 10px;
            -webkit-transition: all 0.5s ease;
            transition: all 0.5s ease;
            display: inline-block;

            cursor: pointer;
            outline: none;
            float: left;
            width: 100%;
            font-style: italic;
            -moz-appearance: none;
            -webkit-appearance: none;
            border: 1px solid grey;
            background: none;
            border-radius: 10px;
            font-weight: 400;
            font-size: 16px;
            color: #000;

            &[disabled] {
                background-color: #F7F7F7 !important;
            }
        }
        @media only screen and (min-width: 768px) {
            .street {
                width: 260px;
            }
            .number {
                width: 80px;
            }
            .block {
                width: 80px;
            }
            .floor {
                width: 80px;
            }
            .ap {
                width: 80px;
            }
            .obs {
                width: 93%;
            }
        }
        @media only screen and (max-width: 620px) {
            label {
                width: 100%;
                margin: 0 0 30px 0;
            }
        }
    }
    .disclaimer {
        font-size: 14px;
        color: #ffffff;
        margin: 0 20px 30px;
        text-transform: none;
    }

    img {
        margin-left: 30px;
        max-width: 400px;
        @media only screen and (max-width: 800px) {max-width: 290px}
        @media only screen and (max-width: 768px) {margin-left: 0;}
        @media only screen and (max-width: 460px) {width: 100%;}
    }
    :global .popup-overlay{
        background: rgba(0,0,0, 0.95) !important;
        z-index: 9999 !important;

        .popup-content{
            position: relative;
            padding: 50px 15px !important;
            width: 90% !important;
            max-width: 900px;
            background: #FFFDFB !important;
            max-height: 85vh;
            overflow-y: auto;

            &::-webkit-scrollbar {
                width: 0;
                background: transparent; /* make scrollbar transparent */
            }

            @media only screen and (max-width: 768px) {
                flex-flow: column-reverse;
                width: 100%;
            }

            .popupMessage {
                display: flex;
                align-items: center;
                width: 50%;
                img.closeImg {
                    width: 25px;
                    height: 25px;
                    cursor: pointer;
                    position: absolute;
                    top: 15px;
                    right: 25px;
                    background: transparent;
                    padding: 0;
                    z-index: 4;
                }

                @media only screen and (max-width: 768px) {
                    width: 100%;
                }

                h2 {
                    font-size: 24px;
                    float:left;
                    font-weight: bold;
                    text-align: left;
                    padding: 0;

                    @media only screen and (max-width: 768px) {

                        padding: 0;
                        text-align: center;
                    }
                }
                p {
                    font-weight: 500;
                    @media only screen and (max-width: 768px) {

                        padding: 0;
                        text-align: center;
                    }
                }
            }
            .popupIcon {
                width: 50%;
                align-items: center;
                justify-content: center;
                display: flex;

                @media only screen and (max-width: 768px) {
                    width: 100%;
                }

                img {
                    position: static;
                    float: right;
                    background: none;
                    height: auto;
                    border-radius: 0;

                    @media only screen and (max-width: 768px) {
                        padding: 0;
                    }
                }
            }
        }
    }

}
.submit {
    width: 100%;
    display: inline-block;
    button {
        margin: 30px 0 30px 60px;
        float: left;
        @media only screen and (max-width: 768px) {
            float: none;
            margin: 30px auto;
        }
    }
}
