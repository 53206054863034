.popup {
    display: inline-block;
    position: relative;
    text-align: left;
    color: #000;

    h2 {
        display: inline-block;
        font-weight: bold;
        text-align: left;
        font-size: 18px;

        margin: 0 0 10px;
    }

    p {
        font-size: 14px;
        button {
            border: none;
            outline: none;
            background: transparent;
            color: #d6a76f;
        }
    }

    span {
        text-align: left;
        color: #d6a76f;

        ul {
            color: #000;
        }
    }

    .cookieForm {
        text-align: center;
    }
}

.saveButton {
    margin: 0 auto !important;
    display: flex;

    font-family: Source Sans Pro;
    text-transform: uppercase;
    letter-spacing: 1.5px;
    line-height: normal;
    text-align: center;
    padding: 10px 15px;
    font-weight: 300;
    cursor: pointer;
    outline: none;
    color: #fff;

    border: 1px solid rgba(255, 255, 255, 0.5);
    background: rgba(0, 0, 0, 0.7);
    margin: 0 10px 10px 0;
    min-width: 150px;

    text-decoration: none;

    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently
        supported by Chrome and Opera */

    &:after {
        transition: all 300ms ease;

        text-decoration: inherit;
        font-family: FontAwesome;
        font-weight: normal;
        font-style: normal;
        content: '\f178';

        position: relative;
        margin-left: 10px;
        font-size: 16px;
        color: #d6a76f;
        top: 1px;
    }

    &:hover {
        background: #d6a76f;
        border-color: #d6a76f;
        text-decoration: none;
        transition: all 300ms ease;

        -webkit-mask-image: linear-gradient(-75deg, rgba(0, 0, 0, 0.8) 30%, #000 50%, rgba(0, 0, 0, 0.8) 70%);
        animation: shine 2s infinite;
        -webkit-mask-size: 200%;

        &:after {
            color: #fff;
        }
    }

    @-webkit-keyframes shine {
        from {
            -webkit-mask-position: 150%;

            &:after {
                margin-left: 10px;
            }
        }

        to {
            -webkit-mask-position: -50%;

            &:after {
                margin-left: 15px;
            }
        }
    }

    @media only screen and (max-width: 420px) {
        line-height: normal;
        text-align: center;
        padding: 12px 17px;
        height: auto;

        margin-bottom: 15px;
        margin-right: 0;
        margin-left: 0;
    }

    @media only screen and (max-width: 320px) {
        margin-bottom: 15px;
        margin-right: 0;
        margin-left: 0;
    }
}
