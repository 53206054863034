.header {
    a.logo {
        background-image: url('../../../assets/images/ui/logo/logo.svg');
        background-repeat: no-repeat;
        background-size: 100%;
        text-indent: -9999px;
        transition: all 300ms ease;
        display: inline-block;
        width: 180px;
        height: 24px;
        left: auto;
        right: auto;

        @media only screen and (max-width: 500px) {
            width: 150px;
        }
    }
}
