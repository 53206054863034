.footer {
    display: inline-block;
    padding: 75px 0 50px;
    background: #34303d;
    position: relative;
    float: left;
    width: 100%;
    z-index: 1;
    margin: 0;

    @media only screen and (max-width: 1024px) {
        padding: 25px 0;
    }

    .container {
        position: relative;
        max-width: 1240px;
        text-align: left;
        margin: 0 auto;
        width: 90%;
    }

    .column {
        display: inline-block;
        text-align: left;
        float: left;
        width: 20%;
        margin: 0;
        font-weight: 500;

        @media only screen and (max-width: 767px) {
            width: 100% !important;
            margin: 0 0 25px;

            &:last-of-type {
                margin: 0;
            }
        }

        &.pullRight {
            float: right;
        }

        &.small {
            ul li a {
                font-size: 16px;
            }
        }

        &.isWide {
            width: 30%;
        }

        h4 {
            font-family: 'iqos', 'Helvetica Neue', sans-serif;
            color: rgba(255, 253, 251, 0.65);
            font-weight: normal;
            margin: 0 0 15px;
            font-size: 14px;
            font-weight: 500;

            @media only screen and (max-width: 767px) {
                margin: 0 0 20px;
            }
        }

        ul {
            display: inline-block;
            list-style: none;
            width: 100%;
            padding: 0;
            margin: 0;

            li {
                display: inline-block;
                padding: 0 10px 0 0;
                margin: 0 0 0 0;
                float: left;
                width: 100%;

                :global(#ot-sdk-btn.ot-sdk-show-settings),
                :global(#ot-sdk-btn.optanon-show-settings) {
                    display: inline-block;
                    text-align: left;
                    background: none;
                    outline: none;
                    border: none;
                    font-weight: 500;
                    font-family: 'iqos', 'Helvetica Neue', sans-serif;
                    line-height: 1.6rem;
                    font-size: 20px;
                    margin: 6px 0;
                    color: #fff;
                    padding: 0;

                    @media only screen and (max-width: 767px) {
                        letter-spacing: 0.2px;
                        line-height: 1.2rem;
                        font-weight: bold;
                        font-size: 16px;
                        margin: 8px 0;
                        opacity: 1;
                    }
                }

                a {
                    font-family: 'iqos', 'Helvetica Neue', sans-serif;
                    display: inline-block;
                    line-height: 1.6rem;
                    font-size: 20px;
                    margin: 6px 0;
                    color: #fff;

                    &:global(.nav-link) {
                        padding: 0;
                    }

                    @media only screen and (max-width: 767px) {
                        letter-spacing: 0.2px;
                        line-height: 1.2rem;
                        font-weight: bold;
                        font-size: 16px;
                        margin: 8px 0;
                        opacity: 1;
                    }
                }
            }
        }
    }
}
